
.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.price {
	font-family: $h;
	font-weight: bold;
	.price-new {
		color: $black;
		font-size: 24px;
	}
	.price-old {
		text-decoration: line-through;
		color: $bk;
		font-weight: 400;
		vertical-align: 4px;
		margin-right: 5px;
	}
	.price-hidden {
		font-size: 18px;
		margin-bottom: 20px;
		display: inline-block;
	}
	&.detail {
		.price-new {
			font-size: 40px;
		}
		.price-old {
			font-size: 26px;
		}
		ul {
			margin: 0;
		}
	}
}

.product-list .price-hidden br {
	display: none;
}

.cheap_invoice {
	a {
		font-size: 16px;
		margin-bottom: 5px;
		text-decoration: underline dotted;
		display: block;
		color: #444;
		&:hover {
			color: $bk;
		}
	}
}

/* product style */
.product-v1 {
	.products-row {
		.product-col {
			border-left: 1px solid $border-color;
			&:first-child {
				border-left-color: transparent;
			}
		}
	}
	.product-block {
		.bottom {
			@include opacity(0);
			@include transition (all 400ms ease 0s);
			bottom: 60px;
			left: 20px;
			width: 100%;
			position: absolute;
			z-index: 999;
		}
		&:hover {
			.product-meta {
				.bottom {
					bottom: 120px;
					@include opacity(1);
				}
			}
		}
	}
}

.product-v3 {
	.product-block {
		.description {
			display: block;
			padding: 10px 0 0;
		}
		.action {
			display: none;
		}
		.price-new {
			font-size: $font-size-base + 5;
		}
		.price-old {
			font-size: $font-size-base + 1;
		}
	}
}

.product-v4 {
	padding: 0;
	.products-row {
		.border {
			border-bottom: $brand-border;
			border-right: $brand-border;
		}
		&.last {
			.border {
				border-bottom: transparent;
			}
		}
	}
	.product-block {
		&:hover {
			@include box-shadow(none !important);
		}
		.block-img {
			float: left;
			margin-right: 10px;
		}
		.name {
			margin: 5px 0;
		}
		.product-meta {
			padding: 20px 20px 0 0;
		}
		.bottom {
			display: none;
		}
	}
}

.product-block {
	position: relative;
	background-color: $white;
	border-top: 1px solid transparent;
	@include rounded-corners(3px);
	.description {
		display: none;
	}
	.product-meta {
		line-height: 25px;
		padding: 0;
		.top {
			.rating {
				text-align: center;
				position: absolute;
				top: -20px;
				width: 100%;
				color: $yl;
				.fa-stack {
					width: 1em;
					height: 1em;
				}
			}
		}
	}
	.bottom {
		display: block;
	}
	.name {
		margin: 10px 0;
		font-size: 18px;
		text-transform: inherit;
		overflow: hidden;
		font-weight: 400;
		a {
			color: $black;
			&:hover {
				color: $theme-color;
			}
		}
	}
	.image {
		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
	}
	.top {
		position: relative;
		padding: 10px 0;
	}
}

.cart {
	position: relative;
	@include transition(all 0.4s ease 0s);
	/*     z-index: 99; */
	padding-bottom: 15px;
	.icon-cart {
		background: url(#{$image-theme-path-base}icon-shopping.png) no-repeat scroll center center;
		display: block;
		@include size(16px, 17px);
	}
}

.product-label {
	color: $product-label-color;
	font-size: $font-size-base - 2;
	font-weight: bold;
	text-align: center;
	position: absolute;
	text-transform: uppercase;
	&.ribbon {
		position: absolute;
		right: 0;
		top: 0;
		@include size(40px, 40px);
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 0;
			height: 0;
			border-top: 40px solid $gray-dark;
			border-left: 40px solid transparent;
		}
		.product-label-special {
			display: block;
			text-align: center;
			height: 100%;
			padding-top: 2px;
			@include rotate(45deg);
		}
	}
	&.ribbon2 {
		width: 85px;
		height: 88px;
		overflow: hidden;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 99;
		display: block;
		font-family: $headings-font-family;
		font-size: 10px;
		.product-label-special {
			display: block;
			text-transform: uppercase;
			text-align: center;
			@include rotate(45deg);
			position: relative;
			padding: 6px 0;
			left: -2px;
			top: 12px;
			width: 120px;
			background-color: $brand-warning;
			color: $black;
			@include box-shadow(0px 0px 3px rgba(255, 255, 255, 0.3));
		}
	}
	&.ribbon1 {
		width: 85px;
		height: 88px;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99;
		display: block;
		font-family: $headings-font-family;
		font-size: 10px;
		.product-label-special {
			display: block;
			text-transform: uppercase;
			text-align: center;
			@include rotate(-45deg);
			position: relative;
			padding: 6px 0;
			left: -32px;
			top: 15px;
			width: 120px;
			background-color: $brand-warning;
			color: $black;
			@include box-shadow(0px 0px 3px rgba(255, 255, 255, 0.3));
		}
	}
	&.bts {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 46px;
		height: 20px;
		position: absolute;
		top: 10px;
		background-color: $theme-color;
		right: 10px;
		border-radius: 2px;
		line-height: 20px;
		.product-label-special {
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
		}
	}
	&.sale {
		text-align: center;
		position: absolute;
		top: 15px;
		background-color: $theme-color;
		left: 15px;
		line-height: 17px;
		padding: 5px 15px;
	}

	&.sale-border {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 40px;
		height: 20px;
		position: absolute;
		top: 15px;
		border: 2px solid #d95e41;
		right: 10px;
		color: #474747;
	}
	&.flag {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 60px;
		height: 30px;
		position: absolute;
		top: 15px;
		background-color: $brand-success;
		left: 0;
		line-height: 30px;
		&:before {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: -15px;
			top: 0;
			border-top: 15px solid $brand-success;
			border-right: 15px solid transparent;
		}
		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: -15px;
			bottom: 0;
			border-bottom: 15px solid $brand-success;
			border-right: 15px solid transparent;
		}
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

h4.text-related {
	padding: 0;
	border-bottom: 1px $bc solid;
	margin-top: 30px;
	margin-bottom: 15px;
	color: $bk;
	text-transform: uppercase;
	font-family: "PT Sans Narrow", sans-serif;
	font-size: 20px;
	font-weight: 700;
	position: relative;
	& > span {
		display: inline-block;
		border-bottom: 5px $tc solid;
		position: relative;
		bottom: -1px;
		padding: 20px 15px;
	}
	button {
		position: absolute;
		right: 80px;
		bottom: 16px;
		line-height: 26px;
	}
}
.widget-products.prelated{
	.owl-next, .owl-prev {
		top: -66px;
	}
}


.product-info .tab-content {
	border: none;
	padding: 0;
	margin-bottom: 30px;
	.desc-header-wrapper {
		border-bottom: 1px $bc solid;
		margin-top: 30px;
		margin-bottom: 15px;
		.desc-header {
			padding: 15px;
			border-bottom: 5px $tc solid;
			color: $bk;
			text-transform: uppercase;
			font-family: $h;
			font-size: 20px;
			font-weight: 700;
			position: relative;
			top: 1px;
			display: inline-block;
		}
	}
	#tab-description {
		padding: 0 15px;
	}
	#tab-specification {
		td {
			padding: 10px 15px !important;
			color: $bk !important;
		}
	}
	#tab-cert {
		img {
			max-width: 50%;
		}
	}
	.for-send-error-press {
		font-style: italic;
		margin: 20px 0 10px;
	}
}

.cheap_invoice {
	margin-bottom: 15px;
}

.storage_widget {
	border-top-color: #ddd;
	& > span {
		color: $theme-color !important;
		@include transition(all .2s ease-in-out);
	}
	table tr {
		td:first-child span {
			width: 12px;
			height: 12px;
			padding: 0 !important;
			display: inline-block;
			vertical-align: -1px;
			border: #ccc 1px solid !important;
			background-image: none !important;
		}
		td:last-child span {
			@include transition(all .2s ease-in-out);
			background: $tc !important;
			border: 1px solid $tc;
			color: $wh !important;
			text-transform: uppercase;
			font-family: $h !important;
			border-radius: 0 !important;
			font-weight: 700;
			font-size: 13px !important;
			&:hover {
				color: $tc !important;
				background: $wh !important;
			}
		}
	}
}

.paging {
	padding: 30px 15px;
	.count {
		color: $tg;
		font-family: $h;
	}
}

.product-markdown-view {
	.price {
		.price-new, .price-old {
			line-height: 1;
			margin: 0;
		}
	}
	.button-credit, .cheap_invoice, .cheap_invoice a {
		margin: 0;
	}
	.product-meta {
		.btn-compare {
			margin: 0;
		}
	}
	.credit {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	.cheaper-products, .cheaper-items, .cheaper-item, .cheaper-item-info, .product-meta, #product  {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.cheaper-products, .cheaper-item {
		&__reason {
			color: $tg;
		}
		&__title {
			font-size: 18px;
			font-weight: 600;
		}
		&__status {
			font-weight: 600;
			color: $tg;
		}
	}
	.cheaper-item {
		padding: 20px;
		border: 1px solid $mg;

		&__price {
			display: flex;
			gap: 16px;
			align-items: flex-end;
		}
	}
	.cheaper-products {
		margin-bottom: 20px;

		&.general {
			margin-top: 20px;

			.cheaper-item {
				border: 1px solid $tg;
			}
		}
	}
}