//== Carousel
// --------------------------------------------------
.vertical .carousel-inner {
  height: 100%;
}

.carousel.vertical .item {
  @include transition(0.6s ease-in-out top);
}

.carousel.vertical .active {
  top: 0;
}

.carousel.vertical .next {
  top: 400px;
}

.carousel.vertical .prev {
  top: -400px;
}

.carousel.vertical .next.left,
.carousel.vertical .prev.right {
  top: 0;
}

.carousel.vertical .active.left {
  top: -400px;
}

.carousel.vertical .active.right {
  top: 400px;
}

.carousel.vertical .item {
    left: 0;
}
