//
// Navs
// --------------------------------------------------
$hot-color: #ff9800 !default;
.badges {
	&.new, &.hot {
		color: $white;
		font-size: $font-size-base - 3;
		height: 15px;
		line-height: 16px;
		padding: 0 5px;
		position: absolute;
		left: 20px;
		@include rounded-corners(2px);
		text-align: center;
		text-transform: uppercase;
		top: -5px;
		transform: translateZ(0px);
		font-family: $font-family-base;
		font-weight: 500;
		&:before {
			border-style: solid;
			border-width: 2px;
			bottom: -5px;
			content: "";
			left: 4px;
			position: absolute;
		}
	}

	&.new {
		background-color: $brand-info;
		&:before {
			border-color: $brand-info transparent transparent;
		}
	}

	&.hot {
		background-color: $hot-color;
		&:before {
			border-color: $hot-color transparent transparent;
		}
	}

}

.red {
	.menu-title {
		color: $red;
	}
}

.#{$app-brand-prefix}-megamenu {
	.navbar {
		margin: 0;
		border: 0;
	}
	.navbar-collapse {
		padding: 0;
	}
	.navbar-nav {
		> li {
			height: 60px;
			&.aligned-left {
				.dropdown-menu {
					left: 0;
					right: auto;
				}
				.dropdown-submenu {
					.dropdown-menu {
						left: 100%;
					}
				}
			}
			> .dropdown-menu {
				top: 100%;
				@include opacity(0);
				@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
				display: block;
				position: absolute;
				visibility: hidden;
			}
			&:hover, &:focus {
				> .dropdown-menu {
					@include opacity(1);
					visibility: visible;
					display: block;
					top: 100%;
				}
			}
			&:first-child > a {
				padding-left: 0;
			}
			> a {
				padding: 19px 22px;
				font-size: 16px;
				font-weight: 700;
				text-transform: uppercase;
				color: $tg;
				background-color: transparent !important;
				@include transition(all 200ms ease-out);
				line-height: 22px;
				font-family: $h;
				&:hover, &:focus, &:active {
					color: $bk;
					.caret:before {
						color: $bk !important;
					}
				}
				.caret {
					border: 0;
					position: relative;
					&:before {
						@include transition(all 200ms ease-out);
						content: '\f107';
						font-family: $fa;
						position: absolute;
						right: -10px;
						top: -12px;
						font-size: $font-size-base - 1;
						font-weight: 100;
						color: $tg;
					}
				}
			}
			&.full-width {
				position: static;
			}
		}
	}
	.panel {
		margin: 0;
	}
	/* level 2 */
	.dropdown-menu {
		top: 100%;
		left: -9999px;
		height: auto;
		color: $text-color;
		border-top: none;
		min-width: $megamenu-sub-min-width;
		padding: 0;
		background-color: $megamenu-sub-bg-color;
		@include rounded-corners(0);
		@include transition(opacity 0.3s linear 0s);
		z-index: 9999;
		ul, ol {
			padding: 0;
		}
		li {
			padding: 0;
			line-height: 20px;
			list-style: none;
			position: relative;
			a {
				padding: 12px 20px;
				display: inline-block;
				width: 100%;
				color: $bk;
				text-transform: uppercase;
				font-size: $font-size-base - 2;
				font-weight: 500;
				&:hover {
					background-color: transparent;
				}
			}
			&.haschildren {
				&:after {
					position: absolute;
					content: "\f054";
					font-size: 10px;
					font-family: $fa;
					background: transparent;
					@include size(10px, 17px);
					right: 5px;
					top: 13px;
				}
				&:hover:after {
					background-position: center -17px;
				}
			}
			> ul.children {
				top: 5px;
				left: 285px;
				right: -250px;
				position: absolute;
				background: $white;
				display: none;
				z-index: 99;
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
				> li {
					a {
						padding: 8px 12px;
						color: $bk;
						border-bottom: 1px #ebebeb solid;
					}
					&:hover {
						background: $tc;
						a {
							color: $white;
						}
					}
					&:last-child {
						border: none;
					}
				}
			}
			&:hover {
				> ul.children {
					left: 100%;
					display: block;
					top: 0;
				}
			}
		}
	}
	.dropdown {
		&:hover {
			> .dropdown-menu {
				display: block;
				left: 0;
			}
			> a {
				.fa {
					color: $bk;
				}
			}
		}
	}
	.mega-group {
		& > .dropdown-toggle {
			border: 0;
			display: block;
			letter-spacing: 3px;
			text-transform: uppercase;
			font-family: $megamenu-heading-title;
			color: $megamenu-heading-color;
			.fa {
				color: $white;
			}
		}
	}
	.widget-heading {
		font-size: $font-size-base + 2;
		margin: 0 0 20px;
	}
	.widget-images {
		margin-bottom: 20px;
		padding-top: 5px;
	}
	.megamenu .cols1 {
		min-width: 200px;
	}
	.megamenu .cols2 {
		min-width: 500px;
	}
	.megamenu .cols3 {
		min-width: 740px;
	}
	.sidebar .product-block {
		padding: 5px 0;
		border-bottom: none;
		.image {
			padding: 0;
		}
	}
}