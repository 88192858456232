// Core variables and mixins

/*Font size
---------------------------------------*/
.font-size-7 {
	font-size: 7px;
}

.font-size-10 {
	font-size: 10px;
}

.font-size-12 {
	font-size: 12px;
}

.font-size-14 {
	font-size: 14px;
}

.font-size-16 {
	font-size: 16px;
}

.font-size-18 {
	font-size: 18px;
}

.font-size-20 {
	font-size: 20px;
}

.font-size-22 {
	font-size: 22px;
}

.font-size-24 {
	font-size: 24px;
}

.font-size-26 {
	font-size: 26px;
}

.font-size-28 {
	font-size: 28px;
}

.font-size-30 {
	font-size: 30px;
}

/*Center Block "Class"*/
.center-block {
	margin: 0 auto;
	text-align: center;
}

/*Divider*/
hr.divider-30 {
	margin: 30px 0;
}

hr.divider-40 {
	margin: 40px 0;
}

hr.divider-50 {
	margin: 50px 0;
}

hr.divider-60 {
	margin: 60px 0;
}

hr.divider-70 {
	margin: 70px 0;
}

hr.divider-snow {
	margin: 50px 0;
	border-color: #ddd;
}

/*Font Weight*/
.fweight-200 {
	font-weight: 200 !important;
}

.fweight-300 {
	font-weight: 300 !important;
}

.fweight-400 {
	font-weight: 400 !important;
}

.fweight-500 {
	font-weight: 500 !important;
}

.fweight-600 {
	font-weight: 600 !important;
}

.fweight-700 {
	font-weight: 700 !important;
}

/*Letter spacing */
.letter-spacing-0 {
	letter-spacing: 0;
}

.letter-spacing-1 {
	letter-spacing: 1px;
}

.letter-spacing-2 {
	letter-spacing: 2px;
}

/*Space Padding
------------------------------------*/
/*Padding Around the Block*/
.space-padding-0 {
	padding: 0 !important;
}

.space-padding-10 {
	padding: 10px !important;
}

.space-padding-20 {
	padding: 20px !important;
}

.space-padding-30 {
	padding: 30px !important;
}

.space-padding-40 {
	padding: 40px !important;
}

.space-padding-50 {
	padding: 50px !important;
}

.space-padding-60 {
	padding: 60px !important;
}

.space-padding-70 {
	padding: 70px !important;
}

.space-padding-80 {
	padding: 80px !important;
}

.space-padding-90 {
	padding: 90px !important;
}

.space-padding-100 {
	padding: 100px !important;
}

/*Padding Top & Bottom*/
.space-padding-tb-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.space-padding-tb-10 {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.space-padding-tb-20 {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.space-padding-tb-30 {
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}

.space-padding-tb-40 {
	padding-top: 40px !important;
	padding-bottom: 40px !important;
}

.space-padding-tb-50 {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
}

.space-padding-tb-60 {
	padding-top: 60px !important;
	padding-bottom: 60px !important;
}

.space-padding-tb-70 {
	padding-top: 70px !important;
	padding-bottom: 70px !important;
}

.space-padding-tb-80 {
	padding-top: 80px !important;
	padding-bottom: 80px !important;
}

.space-padding-tb-100 {
	padding-top: 100px !important;
	padding-bottom: 100px !important;
}

/*Padding Left & Right*/
.space-padding-lr-0 {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.space-padding-lr-10 {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.space-padding-lr-20 {
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.space-padding-lr-30 {
	padding-left: 30px !important;
	padding-right: 30px !important;
}

.space-padding-lr-40 {
	padding-left: 40px !important;
	padding-right: 40px !important;
}

.space-padding-lr-50 {
	padding-left: 50px !important;
	padding-right: 50px !important;
}

.space-padding-lr-60 {
	padding-left: 60px !important;
	padding-right: 60px !important;
}

.space-padding-lr-70 {
	padding-left: 70px !important;
	padding-right: 70px !important;
}

.space-padding-lr-80 {
	padding-left: 80px !important;
	padding-right: 80px !important;
}

.space-padding-lr-90 {
	padding-left: 90px !important;
	padding-right: 90px !important;
}

.space-padding-lr-100 {
	padding-left: 100px !important;
	padding-right: 100px !important;
}

/*Padding Top*/
.space-padding-t5 {
	padding-top: 5px !important;
}

.space-padding-t10 {
	padding-top: 10px !important;
}

.space-padding-t15 {
	padding-top: 15px !important;
}

.space-padding-t20 {
	padding-top: 20px !important;
}

.space-padding-t25 {
	padding-top: 25px !important;
}

.space-padding-t30 {
	padding-top: 30px !important;
}

/*Padding Bottom*/
.space-padding-b5 {
	padding-bottom: 5px !important;
}

.space-padding-b10 {
	padding-bottom: 10px !important;
}

.space-padding-b15 {
	padding-bottom: 15px !important;
}

.space-padding-b20 {
	padding-bottom: 20px !important;
}

.space-padding-b25 {
	padding-bottom: 25px !important;
}

.space-padding-b30 {
	padding-bottom: 30px !important;
}

/*Padding Left*/
.space-padding-l5 {
	padding-left: 5px !important;
}

.space-padding-l10 {
	padding-left: 10px !important;
}

.space-padding-l15 {
	padding-left: 15px !important;
}

.space-padding-l20 {
	padding-left: 20px !important;
}

.space-padding-l25 {
	padding-left: 25px !important;
}

.space-padding-l30 {
	padding-left: 30px !important;
}

/*Padding right*/
.space-padding-r5 {
	padding-right: 5px !important;
}

.space-padding-r10 {
	padding-right: 10px !important;
}

.space-padding-r15 {
	padding-right: 15px !important;
}

.space-padding-r20 {
	padding-right: 20px !important;
}

.space-padding-r25 {
	padding-right: 25px !important;
}

.space-padding-r30 {
	padding-right: 30px !important;
}

/*Space
------------------------------------*/
/*Margin Top*/
.space-top-5 {
	margin-top: 5px !important;
}

.space-top-10 {
	margin-top: 10px !important;
}

.space-top-15 {
	margin-top: 15px !important;
}

.space-top-20 {
	margin-top: 20px !important;
}

.space-top-25 {
	margin-top: 25px !important;
}

.space-top-30 {
	margin-top: 30px !important;
}

.space-top-35 {
	margin-top: 35px !important;
}

.space-top-40 {
	margin-top: 40px !important;
}

.space-top-45 {
	margin-top: 45px !important;
}

.space-top-50 {
	margin-top: 50px !important;
}

/*Margin Bottom*/
.space-5 {
	margin-bottom: 5px !important;
}

.space-10 {
	margin-bottom: 10px !important;
}

.space-15 {
	margin-bottom: 15px !important;
}

.space-20 {
	margin-bottom: 20px !important;
}

.space-25 {
	margin-bottom: 25px !important;
}

.space-30 {
	margin-bottom: 30px !important;
}

.space-35 {
	margin-bottom: 35px !important;
}

.space-40 {
	margin-bottom: 40px !important;
}

.space-45 {
	margin-bottom: 45px !important;
}

.space-50 {
	margin-bottom: 50px !important;
}

.space-55 {
	margin-bottom: 55px !important;
}

.space-60 {
	margin-bottom: 60px !important;
}

.space-65 {
	margin-bottom: 65px !important;
}

.space-70 {
	margin-bottom: 70px !important;
}

.space-75 {
	margin-bottom: 75px !important;
}

.space-80 {
	margin-bottom: 80px !important;
}

.space-85 {
	margin-bottom: 85px !important;
}

.space-90 {
	margin-bottom: 90px !important;
}

.space-95 {
	margin-bottom: 95px !important;
}

.space-100 {
	margin-bottom: 100px !important;
}

/*Margin Left*/
.space-left-5 {
	margin-left: 5px !important;
}

.space-left-10 {
	margin-left: 10px !important;
}

.space-left-15 {
	margin-left: 15px !important;
}

.space-left-20 {
	margin-left: 20px !important;
}

.space-left-25 {
	margin-left: 25px !important;
}

.space-left-30 {
	margin-left: 30px !important;
}

/*Margin right*/
.space-right-5 {
	margin-right: 5px !important;
}

.space-right-10 {
	margin-right: 10px !important;
}

.space-right-15 {
	margin-right: 15px !important;
}

.space-right-20 {
	margin-right: 20px !important;
}

.space-right-25 {
	margin-right: 25px !important;
}

.space-right-30 {
	margin-right: 30px;
}

.space-margin-tb-60 {
	margin-top: 60px !important;
	margin-bottom: 60px !important;
}

/*Removes space*/
.space-top-0 {
	margin-top: 0 !important;
	padding-top: 0 !important;
}

.space-bottom-0 {
	margin-bottom: 0 !important;
	padding-bottom: 0 !important;
}

.no-space-row {
	.row {
		margin: 0;
	}
}

.no-space-row [class^="col-"],
.no-space-row [class*="col-"] {
	padding: 0;
}

/*Space for Ul li List
------------------------------------*/
.ul-space-5 li {
	margin-bottom: 5px !important;
}

.ul-space-10 li {
	margin-bottom: 10px !important;
}

.ul-space-15 li {
	margin-bottom: 15px !important;
}

.ul-space-20 li {
	margin-bottom: 20px !important;
}

.ul-space-25 li {
	margin-bottom: 25px !important;
}

.ul-space-30 li {
	margin-bottom: 30px !important;
}

.ul-space-35 li {
	margin-bottom: 35px !important;
}

.ul-space-40 li {
	margin-bottom: 40px !important;
}

.ul-space-45 li {
	margin-bottom: 45px !important;
}

.ul-space-50 li {
	margin-bottom: 50px !important;
}

/*Height
------------------------------------*/
.height-10 {
	height: 10px;
}

.height-20 {
	height: 20px;
}

.height-30 {
	height: 30px;
}

.height-40 {
	height: 40px;
}

.height-50 {
	height: 50px;
}

.height-60 {
	height: 60px;
}

.height-70 {
	height: 70px;
}

.height-80 {
	height: 80px;
}

.height-90 {
	height: 90px;
}

.height-100 {
	height: 100px;
}

/*Width
------------------------------------*/
.width-10 {
	width: 10%;
	margin: 0 auto;
}

.width-20 {
	width: 20%;
	margin: 0 auto;
}

.width-30 {
	width: 30%;
	margin: 0 auto;
}

.width-40 {
	width: 40%;
	margin: 0 auto;
}

.width-50 {
	width: 50%;
	margin: 0 auto;
}

/*Radius
------------------------------------*/
.radius-0 {
	border-radius: 0 !important;
}

.radius-x {
	border-radius: 50% !important;
}

.radius-1x {
	border-radius: 10px !important;
}

.radius-2x {
	border-radius: 20px !important;
}

.radius-3x {
	border-radius: 30px !important;
}

.radius-4x {
	border-radius: 40px !important;
}

.radius-5x {
	border-radius: 5px !important;
}

.radius-6x {
	border-radius: 3px !important;
}

/* Width for Border */
.border-2 {
	border-width: 2px !important;
}

.border-3 {
	border-width: 3px !important;
}

.border-4 {
	border-width: 4px !important;
}

.border-5 {
	border-width: 5px !important;
}

.border-top {
	border-top: 1px solid $border-color;
}

.border-right {
	border-right: 1px solid $border-color;
}

.border-bottom {
	border-bottom: 1px solid $border-color;
}

.border-after {
	position: relative;
	&:after {
		background: $border-color;
		content: "";
		margin-top: -50px;
		position: absolute;
		top: 50%;
		right: 0;
		@include size(1px, 50%);
	}
}

/*Radius for Left & Right Corners*/
.radius-left-5x {
	border-top-left-radius: 50px !important;
	border-bottom-left-radius: 50px !important;
}

.radius-right-5x {
	border-top-right-radius: 50px !important;
	border-bottom-right-radius: 50px !important;
}

.radius-top-5xxs {
	border-top-left-radius: 5px !important;
	border-top-right-radius: 5px !important;
}

.radius-bottom-5xxs {
	border-bottom-left-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

.radius-left-5xxs {
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}

.radius-right-5xxs {
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

/*Overflow
------------------------------------*/
.overflow-h {
	overflow: hidden;
}

.overflow-a {
	overflow: auto;
}

/***/

.background-img {
	background: $brand-default;
}

.background-img-v1 {
	background: url(#{$image-theme-path}icon-1.png) no-repeat left 25px;
}

.parallax {
	background-size: cover;
	background-attachment: fixed;
	background-position: center center;
	position: relative;
	background-image: $bo-parallax-before-background;
	&:after {
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: -1;
		content: " ";
		height: 100%;
		text-align: center;
		position: absolute;
		background: rgba(0, 0, 0, 0.3);
	}
}

/* icon */
.fa {
	&.fa-2 {
		font-size: 2em;
	}
	&.fa-3 {
		font-size: 4em;
	}
	&.fa-4 {
		font-size: 7em;
	}
	&.fa-5 {
		font-size: 12em;
	}
	&.fa-6 {
		font-size: 20em;
	}
}


/* --- SCSS For Accordion --- */
// Core variables and mixins

.accordion {
	.panel {
		@include box-shadow(0 0px 0px rgba(0, 0, 0, .05));
	}
	.panel-heading {
		padding: 15px;
		position: relative;
		a {
			text-decoration: none;
			font-weight: bold;
			&:before {
				content: "-";
				font-size: 22px;
				font-weight: 600;
				@include size(20px, 20px);
				text-align: center;
				line-height: 16px;
				display: inline-block;
				color: $bo-accordion-color;
			}
			&.collapsed:before {
				content: "+";
				font-size: 18px;
				line-height: 20px;
			}
		}
	}
	.panel-body {
		border-top-color: transparent !important;
	}

	&.collapse-right {
		.panel-heading {
			a:before {
				position: absolute;
				right: 20px;
			}
		}
	}

	&.collapse-color {
		.panel-heading a {
			&:before {
				color: #fff;
				background: $bo-accordion-background;
			}
		}
	}
	&.noborder {
		.panel-heading {
			padding-left: 0;
			padding-right: 0;
		}
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
			border: none;
		}

	}
	&.nobackground {
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
		}
	}
}


.breadcrumbs {
	padding: 22px 15px;
	margin-bottom: 0;
	list-style: none;
	background-color: #f3f3f3;
	border-radius: $border-radius-base;
	.breadcrumb {
		margin: 0;
		padding: 0;
	}
	h2 {
		margin: 0;
		float: left;
		font-weight: 400;
		position: relative;
	}
	.breadcrumb-links {
		margin: 0;
		position: relative;
		padding: 0 0 10px;
		border-bottom: 1px solid $border-color-base;
		> li {
			display: inline-block;
			a {
				text-transform: uppercase;
				font-weight: 500;
				font-size: $font-size-base - 2;
			}
			+ li:before {
				color: #757575;
				content: "\f105";
				font-family: FontAwesome;
				padding: 0 15px;
				font-size: 14px;
			}
			&:last-child {
				a {
					color: $black;
				}
			}
		}
		> .active {
			color: $link-hover-color;
		}
	}
	&.light-style {
		color: #fff;
		border: 0px;
		h2 {
			color: #fff;
		}
		.breadcrumb-links {
			> li {
				a {
					color: #FFF;
				}
				+ li:before {
					color: #fff;
				}
			}
			> .active span {
				color: #fff;
				text-decoration: underline;
			}
		}
	}
}

/* --- SCSS For Buttons --- */
// Core variables and mixins

.btn-outline-light {
	@include button-variant-outline(#bbbbbb, transparent, $border-color, $brand-primary, #FFFFFF, $brand-primary);
}

.btn-inverse-light {
	@include button-variant-outline($brand-primary, #FFFFFF, $brand-primary, #FFFFFF, transparent, #FFFFFF);
}

.btn-outline {
	@include button-outline(default, #000, #FFFFFF);
	@include button-outline(primary, $brand-primary, #FFFFFF);
	@include button-outline(success, $brand-success, #FFFFFF);
	@include button-outline(info, $brand-info, #FFFFFF);
	@include button-outline(danger, $brand-danger, #FFFFFF);
	@include button-outline(warning, $brand-warning, #FFFFFF);
}

.btn-inverse {
	@include button-inverse(default, #000, #FFFFFF);
	@include button-inverse(primary, $brand-primary, #FFFFFF);
	@include button-inverse(success, $brand-success, #FFFFFF);
	@include button-inverse(info, $brand-info, #FFFFFF);
	@include button-inverse(danger, $brand-danger, #FFFFFF);
	@include button-inverse(warning, $brand-warning, #FFFFFF);
}

.btn-3d {
	@include button-3d(lg, -5px, rgba(0, 0, 0, 0.15));
	@include button-3d(empty, -5px, rgba(0, 0, 0, 0.15));
	@include button-3d(sm, -4px, rgba(0, 0, 0, 0.15));
	@include button-3d(xs, -2px, rgba(0, 0, 0, 0.15));
}

.light-style {
	color: #fff;
	h1, h2, h3, h4, h5, h6 {
		color: #fff;
	}
}

/* --- SCSS For List --- */
// Core variables and mixins

/* class list */
.list-unstyled {
	list-style: none;
	padding-left: 0;
}

.lists {
	li {
		margin-bottom: 10px;
		list-style: none;
		a {
			color: $bo-list-a-color;
			&:hover {
				color: $bo-list-a-color-hover;
				text-decoration: none;
			}
		}
		i {
			color: $bo-list-color;
			margin-right: 10px;
			position: relative;
			top: 2px;
		}
	}
	&.lists-light {
		li {
			&, a, i {
				color: $bo-list-light-a-color;
			}
			a {
				&:hover {
					color: $bo-list-light-a-color-hover;
				}
			}
		}
	}
	&.list-style-circle,
	&.list-style-disc {
		list-style: none;
		padding-left: 20px;
		li {
			position: relative;
			&:before {
				color: $bo-list-color;
				font-family: 'FontAwesome';
				font-size: 10px;
				left: -20px;
				position: absolute;
				top: 4px;
			}
		}
		&.lists-light {
			li {
				&:before {
					color: $bo-list-light-color;
				}
			}
		}
	}
	&.list-style-circle {
		li {
			&:before {
				content: "\f10c";
			}
		}
	}
	&.list-style-disc {
		li {
			&:before {
				content: "\f111";
			}
		}
	}
	&.list-style-square {
		list-style: none;
		padding-left: 20px;
		li {
			position: relative;
			&:before {
				content: "";
				top: 10px;
				left: -15px;
				position: absolute;
				@include size(4px, 4px);
				background-color: $bo-list-color;
			}
		}
		&.lists-light {
			li {
				&:before {
					background-color: $bo-list-light-color;
				}
			}
		}
	}

	&.list-style-number {
		counter-reset: li;
		list-style: outside none decimal;
		padding-left: 20px;
		li {
			display: block;
			padding-left: 5px;
			&:before {
				color: $bo-list-color;;
				content: counter(li, decimal);
				counter-increment: li;
				left: 20px;
				position: absolute;
			}

		}
		&.lists-light {
			li {
				&:before {
					color: $bo-list-light-color;
				}
			}
		}
	}
}

/* --- SCSS For Parallax --- */
// Core variables and mixins

.parallax {
	padding-top: 150px;
	padding-bottom: 150px;
	text-align: center;
	.parallax-heading {
		h1, h2, h3, h4, h5, h6 {
			text-transform: uppercase;
		}
	}
	&-v1 {
		.parallax-heading {
			h2 {
				margin: 0;
			}
		}
	}
	&-light {
		h1, h2, h3, h4, h5, h6, p {
			color: #FFF;
		}
	}
}

/* --- SCSS For Table --- */
// Core variables and mixins

.table {
	margin-bottom: 0;
	/*     font-size: 16px; */
}

.table > thead > tr > th {
	border-width: 1px;
	color: #444444;
	font-weight: 600;
}

.table > tbody > tr > td {
	vertical-align: middle;
}

.table {
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> td {
				border-top: medium none;
			}
		}
	}
}

@mixin translateY($y) {
	-webkit-transform: translateY($y);
	-ms-transform: translateY($y); // IE9 only
	-o-transform: translateY($y);
	transform: translateY($y);
}

.success {
	display: none;
	width: 600px;
	margin-left: -300px;
	background: white;
	border: 1px solid #E4E3E3;
	padding: 50px;
	left: 50%;
	top: 22%;
	font-size: 16px;
	color: #333333 !important;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	z-index: 15000;
	position: fixed;
	a {
		text-decoration: none !important;
		font-size: 16px;
		&:hover {
			color: #EC1B24;
			text-decoration: underline;
		}
	}
	.close {
		opacity: 1;
		float: right;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		@include size(24px,24px);
		@media (max-width:767px) {
			@include size(16px,16px);
		}
	}
	.success_button {
		@include transition(0.2s all);
		background: $theme-color;
		border: none;
		display: inline-block;
		cursor: pointer;
		color: white !important;
		font-size: 15px;
		font-weight: bold;
		padding: 6px 24px;
		text-decoration: none;
		&:hover {
			text-decoration: none !important;
			background: $bk;
		}
		&:active {
			position: relative;
			top: 1px;
		}
	}
}