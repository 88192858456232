@media (min-width: 992px) {
	i.click-allcat {
		display: none;
	}
}

@media (max-width: $screen-md-max) {
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li > a {
		padding: 16px 15px;
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.header-top .topcontacts.phone {
		flex: 0 1 350px;
	}
	.product-list .product-block .block-img {
		flex: 0 0 30%;
	}
}

@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.widget-images {
		margin-bottom: 30px;
	}
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}
	.header-top .btn-group {
		margin-right: 10px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 3 - 33px);
			.action > div {
				padding-right: 2px;
			}
		}
	}
	.header-top {
		padding: 0;
		.topcontacts {
			align-self: flex-start;
			.media-body {
				@include flex();
				align-items: center;
			}
		}
		.topcontacts, .topcontacts.phone {
			flex: 0 0 250px;
			padding-left: 0;
			a {
				color: $bk !important;
			}
			.media-title {
				padding: 6px 6px 6px 0;
			}
			.media-body {
				span {
					vertical-align: baseline;
					font-size: 14px;
					color: $bk !important;
				}
			}
		}
		.topcontacts.worktime {
			margin-left: 30px;
			flex: 1 1 auto;
		}
	}
	.header-bottom {
		.main-menu {
			order: 1;
			.pav-megamenu {
				.btn-primary {
					padding: 0 13px;
					&:hover {
						background: $tc;
					}
					span {
						font-size: 18px;
						vertical-align: text-bottom;
					}
				}
			}
		}
		.nav-search {
			order: 3;
		}
		.logo-container {
			order: 2;
			flex: 1 1 auto;
			text-align: center;
		}
	}

	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li:hover {
		background-color: $white;
		a {
			color: $bk;
		}
	}
	.breadcrumbs {
		padding: 15px 15px;
	}
	.h1_container h1 {
		padding: 0 15px 21px 15px;
	}
	.product-compare-header {
		width: 60px;
		position: absolute;
		top: 77px;
		right: calc(((100vw - 780px) / 2) + 80px);
		background: transparent;
		&:hover {
			background: transparent;
		}
		a {
			color: $bk;
		}
	}
	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 10px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
			&.haschildren {

				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 40px);
					display: inline-block;
				}
				&.opened ul {
					display: block;
				}
			}
		}
	}
	.category_widget {
		.type1 {
			height: 300px;
			h2 {
				a {
					font-size: 15px;
					padding: 27px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}
	.shopping-cart-table.table {
		.column-model {
			display: none;
		}
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
}

@media (max-width: $screen-xs-max) {
	.header-center {
		height: 85px;
		.nav-search {
			flex: none;
			padding: 0 30px 0 0;
			position: absolute;
			top: 143px;
			right: 0;
			width: calc(100% - 75px);
			max-width: 300px;
		}
		#searchtop {
			&:before {
				content: none;
			}
			input.form-control {
				height: 30px;
				padding: 0 10px;
			}
			.btn-search {
				height: 30px;
				line-height: 28px;
			}
		}
	}
	.product-compare-header {
		right: 75px;
		top: 67px;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 2 - 32px);
		}
	}
	.logo-container {
		width: 140px;
	}
	.topcontacts {
		width: calc(100% - 140px);
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	.producttabs {
		margin-top: 15px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.tab-v3 {
		.nav-tabs {
			flex-wrap: wrap;
			border: none;
			& > li {
				width: 100%;
				text-align: center;
				border: none;
				&:last-child {
					border: none;
				}
				&.active a {
					border: none;
				}
				& > a {
					border: none;
					padding: 10px 15px;
					font-size: 18px;
				}
			}
		}
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, &.tr {
			display: block;
			border: 0;
			width: 100%;
		}
		td {
			position: relative;
			padding-left: 43% !important;
			text-align: left !important;
			white-space: normal !important;
			&.column-image {
				padding-left: 5px !important;
				text-align: center !important;
			}
			&:first-child {
				border-top: 0;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				@include transform(translateY(-50%));
				font-weight: bold;
				text-transform: uppercase;
				font-family: $h;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}

	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	.header-top {
		.headercart {
			flex: 1 1 200px;
		}
		.topcontacts, .topcontacts.phone {
			flex: 0 0 230px;
		}
	}
	.header-bottom {
		.main-menu {
			flex: 0 0 auto;
		}
		.nav-search {

		}
	}
	#powered .inner {
		flex-wrap: wrap;
		.copyright {
			order: 2;
			margin-bottom: 15px;
		}
		.payment {
			order: 1;
		}
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
		.box .box-heading {
			margin: 15px 0 5px 0;
		}
	}
	.fancybox-opened {
		width: 90% !important;
		position: fixed !important;
		left: 5% !important;
		z-index: 999999 !important;
		top: 35% !important;
		.fancybox-skin {
			padding: 15px 0 !important;
		}
	}
	.fancybox-inner {
		width: 100% !important;
		height: auto !important;
	}
	.holiday_table {
		width: 100% !important;
		td {
			font-size: 15px !important;
		}
	}
	#tab-specification {
		overflow-x: auto;
	}
}

@media (max-width: 610px) {
	.header-top .topcontacts.worktime {
		display: none;
	}
	.certificate-banner {
		.widget-html {
			.img {
				a {
					img {
						max-width: 200%;
					}
				}
			}
		}
	}
}

@media (max-width: 637px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 30px 15px 15px 15px;
		.success_button {
			padding: 6px;
			font-size: 13px;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}
}

@media (max-width: 570px) {
	#powered > .container {
		padding: 0;
	}
	.header-top .topcontacts.phone {
		flex: 0 0 100%;
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	h4.text-related > span {
		padding-bottom: 65px;
	}
}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}

}

@media (max-width: $screen-phone) {
	.product-grid {
		.product-block {
			width: 100%;
		}
	}
	#powered .copyright {
		font-size: 12px;
	}
}
