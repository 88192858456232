.row-offcanvas .menuwall {
	display: none;
}

@media screen and (max-width: $screen-md-max) {
	html.mobmenu {
		overflow: hidden;
	}
	.row-offcanvas {
		position: relative;
		.menuwall {
			display: block;
			visibility: hidden;
			@include transition(opacity 0.25s ease-out);
			@include opacity(0);
		}
		&.active {
			.menuwall {
				position: fixed;
				height: 100vh;
				width: 100vw;
				background: rgba(0, 0, 0, .8);
				z-index: 999;
				@include opacity(1);
				visibility: visible;
			}
			.offcanvas-heading {
				button {
					display: inline-block;
				}
			}
		}
	}
	.row-offcanvas-left {
		left: 0;
		.sidebar-offcanvas {
			position: fixed;
			top: 0;
			@include transition(all 0.25s ease-out);
			left: -100%;
			right: 100%;
			height: 100%;
			background: $wh;
			z-index: 999;
			max-width: 360px;
			border-right: 1px solid $bc;
		}
		&.active {
			.sidebar-offcanvas {
				left: 0;
			}
		}
	}
	.sidebar-offcanvas {
		position: absolute;
		top: 0;
		width: 70%;
		ul, ol {
			padding: 0;
			li {
				list-style: none;
			}
		}
	}
	.offcanvas-inner {
		overflow-y: auto;
		overflow-x: hidden;
		height: 100%;
	}
	.offcanvas-heading {
		position: fixed;
		z-index: 1200;
		right: calc(15vw - 25px);
		top: calc(50vh - 25px);
		button {
			background: none;
			border: none;
			display: none;
			padding: 5px;
			width: 50px;
			height: 50px;
			&:hover, &:active, &:focus {
				background: none;
			}
			svg {
				width: 40px;
				height: 40px;
				fill: $wh;
			}
		}
	}
	.bottom-offcanvas {
		border-top: 1px solid $border-color-base;
		padding: 10px 0;
	}
	.tree-menu ul.cat_all {
		ul {
			padding: 0;
		}
		li  {
			padding: 5px 20px;
			a {
				text-transform: none;
			}
		}
	}
}

/*offcanvas-menu*/
#offcanvasmenu {
	.panel {
		margin: 0;
	}
	.mega-col {
		border-bottom: 1px solid $bc;
	}
	.nav .caret {
		border-bottom-color: $white;
		border-top-color: $white;
		margin-left: 5px;
		display: none !important;
	}
	.pavo-widget .widget-heading {
		margin-top: 15px;
	}
	.navbar-nav {
		float: none;
		margin: 0;

		> li {
			float: none;
			background-color: $white;
			border-right: none;
			&:hover:after {
				display: none;
			}
			.click-canavs-menu {
				cursor: pointer;
				display: inline-block;
				padding: 14px 20px;
				position: absolute;
				right: 0;
				z-index: 1;
			}
			li.dropdown-submenu {
				.click-canavs-menu {
					padding: 10px 25px;
				}
				li.dropdown-submenu {
					> .click-canavs-menu {
						padding: 10px 15px;
					}
				}
			}
			> a {
				display: block;
				@include box-shadow(inset 0 -1px rgba(0, 0, 0, 0.2));
				text-transform: uppercase;
				color: $bk;
				font-family: $h;
				font-weight: bold;
				font-size: 18px;
			}
		}
	}
	.dropdown-menu {
		border: 0 none;
		border-radius: 0;
		box-shadow: none;
		display: none;
		float: none;
		margin: 0;
		padding: 0 10px;
		position: static;
		visibility: visible;
		width: auto !important;
		min-width: 160px;
		@include opacity (1);
		a {
			font-size: 18px;
			padding: 5px 0;
			display: block;
			color: $text-color;
			font-family: $h;
			&:hover {
				color: $theme-color;
			}
		}
	}
	.w-product {
		padding: 0;
	}
	.product-block {
		border: none;
		padding: 0;
		.image {
			float: none;
			margin: 0;
		}
	}
	.fa-minus-square:before {
		content: '\f068';
	}
	.fa-plus-square:before {
		content: '\f067';
	}
}

.canvas-menu {
	margin: 0;
	background: transparent;
	color: $tc;
	font-size: 28px;
	padding: 0 10px;
	border: none;
}