.breadcrumb {
	> li {
		display: inline;
		+ li:before {
			content: "\f105\00a0";
			padding: 0 1px 0 5px;
			color: $text-color;
			font-family: "FontAwesome";
		}
	}
	> .active {
		font-weight: 600;
	}
}
