$f: "PT Sans", sans-serif !default;
$h: "PT Sans Narrow", sans-serif !default;
$fa: "FontAwesome" !default;
$bk: #212121 !default;
$black: $bk !default;
$wh: #fff !default;
$gd: #00583d !default;
$gl: #119b71 !default;
$tc: #00583d !default;
$lg: #f6f6f6 !default;
$tg: #999 !default;
$bc: #e8e8e8 !default;
$mg: #c8c8c8 !default;
$dg1: #222 !default;
$dg2: #1b1b1b !default;
$yl: #ffd63a !default;
$or: #ffbf3a !default;

@import "fonts";
@import "bootstrap";
@import "vars/opencart";
@import "vars/elements.vars";
@import "mixins/app";
@import "app/base";
@import "app/elements";
@import "app/form";
@import "app/layout";
@import "app/block";
@import "app/product";
@import "app/modules";
@import "app/menu";
@import "app/animation";
@import "opencart/opencart-modules";
@import "opencart/opencart-pages";
@import "opencart/offcanvas";
@import "opencart/style";
@import "opencart/theme";

@import "opencart/modules/megamenu.scss";
@import "opencart/modules/carousel.scss";

@import "app/custom";
@import "opencart/responsive";