body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

/* search */

.form-control::-moz-placeholder {
	color: $link-color;
}

// module cart
#cart {
	&.open .dropdown-toggle {
		box-shadow: none;
	}
	a {
		display: inline-block;
		position: relative;
	}
	.icon-cart {
		background: transparent;
		@include size(55px, 47px);
		display: inline-block;
		margin-right: 10px;
		position: relative;
		text-align: center;
		padding: 5px;
		svg {
			width: 36px;
			height: 36px;
		}
	}
	.wrap-cart {
		display: inline-block;
		color: $black;
		span {
			position: relative;
			top: 10px;
		}
		.cart-total {
			font-weight: bold;
			font-family:$h;
			color: $tg;
			@include transition(all 200ms ease-out);
		}
		.badge {
			position: absolute !important;
			top: 5px !important;
			right: 15px;
			padding: 3px;
			min-width: 17px;
			line-height: 11px;
			z-index: 99;
			background: $theme-color;
			@include rounded-corners(50%);
		}
		&:hover {
			.cart-total {
				color: $bk;
			}
		}
	}
	.price-cart {
		color: $theme-color;
	}
	.cart-inner {
		text-align: right;
		position: relative;
		top: 4px;
	}
}