// pav carousel
.#{$app-brand-prefix}carousel {
    .item-inner{
        &:hover {            
            img {
                @extend .grayscale-color;
            }
        }
        img {
            @extend .grayscale-gray;  
        } 
    }
    /* &:hover {
        .carousel-control {
           &.left{
               left: 10px;
            }   
            &.right{
                right: 10px;
            } 
        }
    } */
}