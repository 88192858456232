/******************************************************
 * $package Pav Opencart Theme Framework for Opencart 1.5.x
 * $version 1.1
 * $author http://www.pavothemes.com
 * $copyright   Copyright (C) Augus 2013 PavoThemes.com <$emai:pavothemes$gmail.com>.All rights reserved.
 * $license     GNU General Public License version 2
 *******************************************************/
html {
	overflow-x: hidden;
}

body {
	&.layout-boxed-lg {
		#page {
			max-width: $container-lg + 20px;
			@include box-shadow(0 0 5px lighten($gray, 50%));
			margin: 0 auto;
			background: $white;
		}
	}
	&[class*="-home"] {
		.header-1 {
			.pav-verticalmenu {
				display: none !important;
				border: 1px solid #000;
			}
			.dropdown {
				&:hover {
					.pav-verticalmenu {
						display: block;
					}
				}
			}

		}
	}
	&[class*="page-"] #content {
		padding: 20px 15px;
	}
	&[class*="page-category"] {
		#content {
			padding: 0;
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="-account"] #content {
		h1, h2 {
			font-size: $font-size-base + 5;
		}
	}

	&[class*="page-product"] #content {
		overflow: visible;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
		.owl-carousel {
			margin-bottom: 35px;
		}
	}
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

/*
* HEADER 
*/

#topbar {
	background: $topbar-outside-bg;
	font-size: $topbar-font-size;
	color: $topbar-color;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;
	.topbar-left {
		position: relative;
	}
	.custom-top {
		&:nth-child(1) {
			margin-right: 20px;
		}
		i {
			padding-right: 5px;
		}
	}
	.container {
		.inside {
			background: $topbar-bg;
			padding: $topbar-container-padding;
		}
	}

}

.header-top {
	height: 48px;
	padding: 6px 0;
	background-color: $lg;
	color: $bk;
	font-size: 16px;
	font-family: $h;
	font-weight: 400;
	text-transform: uppercase;
	& > .container {
		position: relative;
		@include flex();
	}
	.btn-group {
		margin-right: 20px;
		button {
			background-color: transparent;
			border: none;
			padding: 0;
		}
	}
	.topcontacts {
		flex: 0 1 400px;
		align-self: center;
		@include flex();
		.media-title {
			font-size: 20px;
			padding: 0 10px 0 0;
		}
		.media-body {
			span {
				margin-right: 5px;
				display: inline-block;
				vertical-align: -4px;
				position: relative;
				&:first-child {
					&:after {
						content: ',';
					}
				}
				a {
					color: $bk !important;
				}
			}
		}
		svg {
			width: 18px;
			height: 20px;
			vertical-align: -3px;
		}
		&.phone {
			flex: 0 1 265px;
		}
	}
}

.header-center {
	height: 105px;
	& > .container {
		@include flex();
		height: 100%;
		align-items: center;
	}
	.logo-container {
		flex: 0 0 160px;
	}
	.nav-search {
		flex: 0 0 375px;
		padding: 0 30px;
	}
	.headercart {
		flex: 1 1 200px;
	}
	#searchtop {
		&:before {
			content: '';
			position: absolute;
			left: 10px;
			top: 13px;
			width: 18px;
			height: 20px;
			background: transparent url(https://cdn.garrettrussia.ru/image/i/search.svg) no-repeat;
			background-size: 18px 20px;
			padding: 10px 10px 10px 35px;
		}
		input.form-control {
			padding: 0 10px 0 35px;
			background: transparent;
			border-radius: 0;
			border: 1px $bc solid;
			color: $bk;
			font-family: $h;
			font-style: normal;
			height: 45px;
			@include placeholder($mg);
			margin-right: 10px;
		}
		.btn-search {
			margin-left: 10px;
			padding: 0 15px;
			height: 45px;
			font-weight: 700;
			font-family: $h;
			font-size: 16px;
			text-transform: uppercase;
		}
	}
}

.header-bottom {
	background: $wh;
	border-bottom: 2px $bc solid;
	border-top: 2px $bc solid;
	& > .container {
		@include flex();
	}
	.logo-container {
		flex: none;
	}
	.main-menu {
		flex: 1 0 auto;
		align-self: center;
	}
}

.shopping-cart-table {
	background: $wh;
	tbody {
		.column-image {
			position: relative;
			img {
				border: none;
			}
			.product-label {
				top: 5px;
				left: 5px;
				padding: 0 5px;
				background: $tc;
			}
		}
	}
	& > thead:first-child > tr:first-child > td {
		border-top: 1px solid #ddd;
		font-family: $h;
		font-size: 18px;
		font-weight: bold;
	}
	&.table {
		tr td {
			border: 1px #ddd solid;
		}
	}

}

.tp-bullets .counter {
	display: none !important;
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/* multi logo */
.logo-store {
	position: relative;
	a {
		vertical-align: middle;
	}
	span {
		font-size: 14px;
		text-align: center;
		display: block;
		white-space: nowrap;
		font-family: "PT Sans Narrow", sans-serif;
		color: #999;
		margin-left: -5px;
	}
}

#logo-theme {
	a {
		background: url(#{$image-theme-path}logo.png) no-repeat;
		display: inline-block;
		@include size(170px, 37px);
		span {
			display: none;
		}
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

/*
* NAVIGATION
*/
#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

/*
* SLIDESHOW 
*/
#pavo-slideshow {
}

/*
* SHOWCASE 
*/
#pavo-showcase {
	.inner {
		background-color: $showcase-bg;
	}
}

/*
* PROMOTION TOP 
*/
#pav-promotion {
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

/*
* MAIN CONTENT
*/

/*end*/
/* FOOTER */
#footer {
	ul, ol {
		margin: 0;
		li {
			line-height: 20px;
			font-size: 14px;
			color: transparentize($wh, .5);
			a {
				font-size: $font-size-base - 2;
				color: transparentize($wh, .5);
				font-weight: 500;
				@include transition(all 0.4s ease 0s);
				&:hover {
					color: $gl;
				}
			}
		}
	}
}

footer {
	margin-top: 15px;
}

.footer-top {
	background: transparent;
	margin-bottom: -97px;
}

.footer-center {
	background: $dg1;
	padding: 30px 0;
	color: $white;
	.box {
		.box-heading {
			font-size: 20px;
			text-transform: uppercase;
			font-weight: 600;
			margin-bottom: 22px;
			font-family: $h;
		}
		&.contacts {
			ul {
				li {
					margin-bottom: 5px;
					padding-left: 20px;
					background: no-repeat transparent 0 3px;
					background-size: 14px;
					&.ft- {
						&addr {
							background-image: url(https://cdn.garrettrussia.ru/image/i/w-map.svg);
						}
						&phone {
							background-image: url(https://cdn.garrettrussia.ru/image/i/w-phone.svg);
						}
						&wt {
							background-image: url(https://cdn.garrettrussia.ru/image/i/w-clock.svg);
						}
						&mail {
							background-image: url(https://cdn.garrettrussia.ru/image/i/w-mail.svg);
						}
					}
				}
			}
		}
	}
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $white;
		}
	}
	.footer-name {
		text-align: center;
		font-size: 12px !important;
		margin-top: 10px;
		img {
			filter: brightness(1.2);
		}
	}
	.main-footer {
		@include flex();
		justify-content: space-between;
	}
}

.footer-bottom {
	background: $blue;
	.inside {
		background: url("#{$image-theme-path}pattern.jpg") repeat 0 0;
		position: relative;
		@include rounded-corners(3px);
		@include element-absolute($white);
		.bg-blue {
			background: url("#{$image-theme-path}pattern2.jpg") repeat 0 0;
		}
	}
}

/* POWER BY */
#powered {
	background: $dg2;
	a {
		font-size: $font-size-base - 2;
		color: $footer-color;
		font-weight: 500;
		text-transform: uppercase;
		width: 100%;
		&:hover {
			color: $white;
		}
	}
	.copyright {
		color: $white;
		font-size: $font-size-base - 2;
		font-weight: 500;
		text-transform: uppercase;
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.inner {
		@include flex();
		flex-wrap: wrap;
		align-items: center;
		.oferta p {
			color: $wh;
			font-size: 12px;
			margin-bottom: 3px;
		}
	}
	.payment-img {
		text-align: right;

		img {
			max-height: 50px;
		}
	}
}