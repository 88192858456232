// banner builder
.banner-wrapper-margin {
	.banner-wrapper {
		margin-bottom: 20px;
	}
}

.image-item-margin {
	.image-item {
		margin-bottom: 20px;
	}
}

.nomargin .box {
	margin-bottom: 0;
}

/* slidelayer */
.layerslider-wrapper {
	overflow: hidden;
	&:hover {
		.tp-rightarrow {
			right: 20px !important;
		}
		.tp-leftarrow {
			left: 20px !important;
		}
	}
}

.tparrows {
	position: absolute;
	top: 50% !important;
	z-index: 9999;
	cursor: pointer;
	display: block;
	@include size(45px, 45px);
	@include transition(all 0.3s ease-in-out 0s);
	font-size: 0;
	&:before {
		content: "";
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		z-index: 12;
		background: url("#{$image-theme-path}bullet4.png") no-repeat 0 -2px;
		@include size(40px, 35px);
		@include transition(all 0.35s ease 0s);
		display: block;
	}
	&.tp-rightarrow {
		&:before {
			background-position: -50px -2px;
		}
	}
}

.tp-leftarrow {
	left: -50px !important;
}

.tp-rightarrow {
	right: -50px !important;
}

.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected {
	background-color: $theme-color;
}

.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected,
.tp-bullets.simplebullets.navbar .bullet:hover,
.tp-bullets.simplebullets.navbar .bullet.selected {
	background-color: $theme-color;
}
