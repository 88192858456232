.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 0;
	font-family: $h;
	> li {
		display: inline;
		> a, > span {
			position: relative;
			float: left;
			margin-right: 8px;
			line-height: 32px;
			padding: 0 7px;
			font-size: 18px;
			text-decoration: none;
			text-align: center;
			color: $gl;
			@include transition(color 200ms ease-out);
		}
		&:first-child {
			> a, > span {
				margin-left: 0;
			}
		}
	}
	> li > a, > li > span {
		&:hover, &:focus {
			color: $bk;
		}
	}
	> .active > a, > .active > span {
		&, &:hover, &:focus {
			color: $tg;
			font-weight: 700;
			cursor: default;
		}
	}
	> .disabled {
		> span, > span:hover, > span:focus, > a, > a:hover, > a:focus {
			color: $tg;
			font-weight: 400;
		}
	}
}
