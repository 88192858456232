.owl-carousel {
	display: block !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.filter-right {
	& > div {
		@include flex();
		align-items: center;
		font-family: $h;
		font-size: 16px;
		font-weight: 700;
		color: $tg;
	}
	.sort {
		margin-left: 15px;
	}
	label {
		line-height: 14px;
	}
}

.refine-search {
	.col-sm-12 {
		padding: 0;
	}
	ul, ol {
		list-style: none;
		@include flex();
		flex-wrap: wrap;
		li {
			padding: 5px 0 5px 10px;
			margin-right: 10px;
			line-height: normal;
			position: relative;
			width: calc(25% - 20px);
			a {
				@include flex();
				flex-wrap: wrap;
				justify-content: center;
				color: $bk;
				align-items: center;
				img {
					max-width: calc(100% - 45px);
				}
				span {
					padding-top: 10px;
					text-align: center;
					font-weight: 600;
					font-family: $h;
					font-size: 18px;
					display: block;
					width: 100%;
				}
			}
			@media (max-width: 767px) {
				width: calc((100% / 3) - 20px);
			}
			@media (max-width: 500px) {
				width: calc((100% / 2) - 20px);
			}
			@media (max-width: 400px) {
				font-size: 12px;
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.col-md-12 {
	.refine-search ul li, .refine-search ol li {
		width: calc(100% / 5 - 10px);
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}

}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
	.prod_rating {
		.stars {
			color: $yl;
		}
		#scr_revs:hover, #scr_newrev:hover {
			cursor: pointer;
			color: $gd;
		}
	}
}

#tab_review {
	.review {
		.review-header {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 15px;
			border: 1px solid $bc;
			border-bottom: none;
			.author {
				& > span:first-child {
					font-weight: 600;
					margin-right: 7px;
				}
			}
			.stars-block {
				color: $yl;
			}
		}
		.review-list {
			padding: 15px;
			border: 1px solid $bc;
			margin-bottom: 10px;
			span {
				font-weight: 600;
			}
		}
	}
}
#form-review-container {
	.set-rating {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		margin-top: 6px;
		margin-bottom: 2px;
		height: 16px;
		i {
			margin: 0px;
			display: block;
			float: left;
			padding: 0px 4px 0px 0px;
			cursor: pointer;
			color: $bc;
			&.selected {
				color: $yl;
			}
		}
		& > i:hover, & > i:hover ~ i {
			color: $or;
		}
	}
	.recaptcha_container {
		.control-label {
			display: none;
		}
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

/* 
 *  LISTING PRODUCTS PAGE 
 */
.product-filter {
	color: $bk;
	font-size: 14px;
	letter-spacing: .8px;
	font-weight: 400;
	span {
		margin-right: 5px;
	}
	.filter-right > div {
		padding-left: 5px;
	}

	.display {
		float: left;
	}
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
		text-transform: capitalize;
	}
	.btn-switch {
		display: inline-block;
		margin-right: 5px;
		color: $tg;
		background: $wh;
		padding: 0;
		line-height: 20px;
		font-weight: 500;
		font-size: 20px;
		height: 28px;
		@include transition (all 200ms ease 0s);
		i {
			padding: 0 5px;
		}
		&:hover, &.active {
			color: $wh;
			background: $tg;
			@include box-shadow (none);
		}
	}
}

.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding-left: 0;
		.top {
			display: flex;
			flex-wrap: wrap;
			.name {
				order: 1;
			}
			.rating {
				position: initial;
				text-align: left;
				order: 2;
				height: 30px;
				.wrap {
					height: inherit;
					.fa {
						height: inherit;
					}
				}
			}
			.price {
				order: 3;
				width: 100%;
			}
			.description {
				order: 4;
			}
		}
	}
	.product-block {
		@include flex();
		border: 1px solid #eee;
		margin: 10px 0;
		border-radius: 0;
		.name {
			font-weight: 600;
			font-size: 18px;
		}
		.block-img {
			flex: 0 0 25%;
		}
		.bottom {
			padding: 20px 0;
			.action {
				display: inline-block;
				padding: 4px 0;
				margin-left: 15px;
			}
			.cart {
				display: inline-block;
				button {
					padding: 0 30px;
				}
			}
		}
	}
}

.product-grid {
	.products-block {
		@include flex();
		flex-wrap: wrap;
		.product-block {
			padding: 15px;
		}
	}
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			padding: 15px 0;
		}
		.product-block {
			width: calc(100% / 4);
		}
	}
	.product-block {
		display: inline-block;
		margin: 0;
		width: calc(100% / 4);
		@include transition(box-shadow 0.2s ease-in-out);
		.cart {
			padding: 0;
		}
		.bottom {
			@include transition (all 400ms ease 0s);
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 999;
			background-color: $white;
			text-align: center;
			padding: 0;
			@include flex();
			justify-content: space-between;
			align-items: flex-end;
		}
	}
}

.product-block {
	.action {
		text-align: center;
		> div {
			display: inline-block;
			position: relative;
			margin-bottom: 5px;
			top: 5px;
			span {
				display: none;
			}
		}
		button, a {
			border: 0;
			background: transparent;
			display: inline-block;
			width: 35px;
			height: 28px;
			margin-left: 10px;
			&:hover {
				svg {
					fill: $tc;
				}
			}
		}
		svg {
			@include size(26px, 26px);
			fill: $tg;
			@include transition(all 0.2s ease-in-out);
		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

/* 
* PRODUCT DEALS
*/
.productdeals {
	.widget-inner {
		.carousel {
			position: static;
		}
	}
}

/* 
* PRODUCT INFORMATION PAGE
*/
.product-info {
	.box-product-infomation {
		margin-top: 20px;
	}
	.title-product {
		font-size: 30px;
		margin-top: 0;
		font-weight: 600;
		border-bottom: 1px $bc solid;
		padding-top: 0;
	}
	.image-additional {
		overflow: hidden;
		.owl-carousel {
			margin-bottom: 0 !important;
			padding: 20px;
			.owl-prev, .owl-next {
				top: calc(50% - 17px);
			}
			.owl-prev {
				left: 3px;
				right: auto;
			}
			.owl-next {
				right: 0;
			}
		}
	}
	.image-additional .item {
		border: 1px solid $border-color;
		text-align: center;
		margin: 0 2px;
		background-color: $wh;
		position: relative;
		a.active {
			&:before {
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $tc;
				content: "";
				left: 0;
				z-index: 1;
				top: -1px;
			}
			&:after {
				position: absolute;
				left: 50%;
				margin-left: -8px;
				border: 6px solid transparent;
				border-bottom: 6px solid $tc;
				content: "";
				z-index: 1;
				top: -12px;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.vertical {
		&.image-additional {
			.carousel-control {
				display: none;
			}
		}
		&:hover {
			.carousel-control {
				display: block;
			}
		}

		.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		.top {
			top: 0;
			bottom: auto;
		}

		.bottom {
			top: auto;
			bottom: 0;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;

		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.attribs {
		.check-box {
			padding-right: 15px;
			background: url("https://cdn.garrettrussia.ru/image/i/g-check.svg") no-repeat center left transparent;
			width: 14px;
			height: 14px;
			display: inline-block;
			vertical-align: -1px;
		}
		li {
			b {
				font-family: $h;
			}
		}
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: 1px solid $border-color;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $white;
		@include border-radius($border-color, 0);
		& + .btn-sm {
			padding: 0 18px;
		}
		.add-action {
			@include size(40px, 39px);
			color: $black;
			display: block;
			position: relative;
			cursor: pointer;
			font-size: 8px;
			line-height: 39px;
			text-align: center;
			&:hover, &:focus {
				color: $theme-color;
			}
		}
		.quantity-number {
			input {
				@include size(40px, 40px);
				text-align: center;
				@include inline-block;
				@include rounded-corners(0);
				box-shadow: none;
				padding: 0;
				border: none;
				color: $black;
				font-style: normal;
			}
		}
		.minimum {
			line-height: 38px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		a {
			a.btn-sm {
				padding: 0 19px;
			}
			margin-left: 16px;
			color: #ccd6dd;
			font-weight: 500;
		}
		.cart {
			padding-bottom: 0;
		}
		.btn-compare {
			color: $bk;
			font-size: 24px;
			padding: 2px 18px;
			height: 41px;
			&:hover {
				color: $tc;
			}
		}
	}

}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.h1_container {
	font-size: 26px;
	h1 {
		margin: 0 auto;
		font-size: 30px;
		font-weight: 700;
		text-transform: uppercase;
		padding: 15px;
	}
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}
